@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;900&display=swap');

/* ————————————————————————————————————————————————
Colors Variables
————————————————————————————————————————————————— */
:root {
  --primary-blue: #007CBA;
  --primary-blue-light: #2391c8;
  --primary-blue-lighter: #89d8ff;
  --primary-blue-dark: #00628A;
  --primary-blue-darker: #015779;
  --secondary-design: #B12851;
  --secondary-learn: #37A168;
  --section-headlines: #353535;
  --light-grey: #c6cccf;
  --mid-grey1: #868686;
  --mid-grey: #545657;
  --dark-gray: #202120;
  --darker-gray: #191a19;
  --nav-filter-links: #6C6C6C;
}



.g_bg-color {
  background: var(--dark-gray);
}
.g_bg-lighter-color {
  background: var(--light-grey);
}
.g_secondary-blue-color {
  background: var(--primary-blue-dark);
}

.g_secondary-design-color {
  background: var(--secondary-design);
}

.g_secondary-learn-color {
  background: var(--secondary-learn);
}


.g_section-headlines-color {
  color: var(--section-headlines);
}
.g_white-color {
  color: white;
}

/* Any class pre-pended with "g_" is a custom class that is applied globally */

/* ————————————————————————————————————————————————
Base Styles
————————————————————————————————————————————————— */


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-blue-dark) var(--darker-gray);
}
::-webkit-scrollbar-track {
  background: var(--darker-gray);
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary-blue-dark) ;
  border-radius: 6px;
  border: 3px solid var(--darker-gray);
}


body {
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dark-gray);
  font-weight: 600;
  font-size: 16px;
}

header {
  text-align: center;
  padding: 170px 0 190px;
}

hr {
  border-top: solid var(--primary-blue) 9px;

  /* width: 210px;   */
  border-radius: 20px;
  width: 9px;
}

a {
  color: white;
  transition: ease 0.3s;
}

a:hover {
  color: white;
}

.g__footer-pin {
  min-height: calc(100vh - 315px);
}

s {
  display: inline-block;
  text-decoration: none;
  position: relative;
}

s:after {
  content: '';
  display: block;
  width: 100%;
  height: 63%;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 2px solid;
}



/* ————————————————————————————————————————————————
Fonts
————————————————————————————————————————————————— */
h1 {
  font-weight: 900;
  font-size: 36px;
  line-height: 140%;
  max-width: 350px;
  margin: 0 auto;
}

h2 {
  font-weight: 900;
  font-size: 180px;
  line-height: 140%;
  text-align: center;
  color: var(--section-headlines);
  margin-top: -5rem;
}

/* h3 is given several unique styles in the areas it is used. */

h4 {
  font-weight: 900;
  font-size: 16px;
}

h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: var(--light-grey);
}

.g_txt-bold {
  font-weight: 900;
}

.g__body-lg {
  font-size: 22px;
  /* margin-bottom: 30px; */
  color: white;
}


/* ————————————————————————————————————————————————
Forms Overrides
————————————————————————————————————————————————— */
.form-control {
  background-color: var(--section-headlines);
  color: aliceblue;
  border: 1px var(--dark-gray) solid;
  /* margin-bottom: 30px; */
  height: 50px;
  padding: 15px;
}

.form-control:focus {
  background-color: rgb(29, 29, 29);
  color: rgb(223, 223, 223);
  border-color: var(--primary-blue-dark);
}

label {
  color: var(--light-grey);
}

.invalid-feedback {
  display: block;
}


/* ————————————————————————————————————————————————
Buttons
————————————————————————————————————————————————— */

.btn-primary {
  background-color: var(--primary-blue-dark);
  width: 130px;
}

.btn-primary,
.btn,
.contact__submit-btn {
    border-color: initial;
    border: none;
}

button {
  background: none;
  border: none;
}


/* ————————————————————————————————————————————————
Flex Utils
————————————————————————————————————————————————— */
.g_justify-end {
  justify-content: flex-end;
}



/* ————————————————————————————————————————————————
Hidden Util
————————————————————————————————————————————————— */
.hidden {
  display: none;
}




/* ————————————————————————————————————————————————
Section Margins
————————————————————————————————————————————————— */
.g_negative-margin {
  margin-top: -6.5rem;
  margin-right: auto;
  margin-left: auto;
}


/* ————————————————————————————————————————————————
Project Styles
————————————————————————————————————————————————— */
  .g_projects-container {
      /* margin-top: -170px; */


  }


  .g_meta-container {
  background: var(--primary-blue);
  position: relative;
  top: -126px;
  /* min-height: 252px; */
  border-radius: 18px;
  z-index: 1;
  margin-bottom: 1rem;
  color: white;

}

.g_project-img {
  width: 100%;
  min-height: 200px;
  border-radius: 18px;
  transition: ease .5s;
  z-index: 3000;
}

.g_card-slide,
.g__design-project__card-slide {
  position: relative;
  top: 0;
  z-index: 2;
  color: initial;
  padding: 0;
  border-radius: 23px;
  display: inline-block;
  -webkit-box-shadow: 0 4px 23px 0 rgba(0,0,0,0.65);
     -moz-box-shadow: 0 4px 23px 0 rgba(0,0,0,0.65);
          box-shadow: 0 4px 23px 0 rgba(0,0,0,0.65);
          transition: ease .5s;
}

.g_card-slide:hover,
.g__design-project__card-slide:hover {
  -webkit-box-shadow: 0 20px 50px 0 rgba(0,0,0,0.95);
  -moz-box-shadow: 0 20px 50px 0 rgba(0,0,0,0.95);
       box-shadow: 0 20px 50px 0 rgba(0,0,0,0.95);
       transition: ease .5s;
}

.g_card-slide:focus,
.g__design-project__card-slide:focus {
  border: none;
  outline: none;
}

.g_card-slide-up {
  top: -30px;
  z-index: 2;
  transition: ease .5s;
  /* top: 176px; */
}


.g__design-project__card-slide {
  top: 50px;
}



/* ————————————————————————————————————————————————
Accordion Overrides
————————————————————————————————————————————————— */

.accordion>.card {
  overflow: visible;
}

.card {
  position: initial;
  display: block;
  min-width: 0;
  word-wrap: break-word;
  background: none;
  background-clip: border-box;
  border: none;
  border-radius: 0;
}

.card-header {
  padding: 0;
    margin-bottom: 0;
    background: none;
    border-bottom: none;
}

.card-body {
  padding: 0;
}


/* ————————————————————————————————————————————————
Global About Page
————————————————————————————————————————————————— */
.g__about-sections {
  margin-top: 10rem;
}

.g__frame-left {
  position: fixed;
  left: 40px;
  width: 20px;
  height: 100vh;
  border-right: 1px solid #353535;
}

.g__frame-right {
  position: fixed;
  right: 60px;
  width: 20px;
  height: 100vh;
  border-right: 1px solid #353535;
}




/* ————————————————————————————————————————————————
///////////////////////////////////////////////////

Mobile Styles

///////////////////////////////////////////////////
————————————————————————————————————————————————— */

/* 1201px — Breakpoint */
@media only screen and (max-width: 1201px) {

  h2 {
    font-size: 155px;
  }

  .g_negative-margin {
    margin-top: -5rem;
    transition: ease 1s;
  }


}


/* 993px — Breakpoint */
@media only screen and (max-width: 993px) {

  h2 {
    font-size: 110px;
  }

  .g_negative-margin {
    margin-top: -4rem;
    transition: ease 1s;
  }

  .g_card-slide,
  .g_project-img,
  .g_meta-container {
      border-radius: 30px;
  }

}


/* 809px — Breakpoint */
@media screen and (max-width: 809px) {
  .g__frame-left,
  .g__frame-right {
  display: none;
  }
}


/* 767px — Breakpoint */
@media only screen and (max-width: 767px) {
  .navbar-collapse {
    padding: 0 10px;
    text-align: center;
  }

  header {
    padding: 80px 0;
  }

  h2 {
    font-size: 70px;
    line-height: 90%;
    margin-top: 2rem;
  }

  .g_negative-margin {
    margin-top: -1.5rem;
    transition: ease 1s;
  }

  .g__design-project__card-slide {
    border-radius: 23px;
  }

  .design-project__meta-container {
    padding-top: 105px;
    height: 145px;
    top: -40px;
  }

  .design-project__container {
    margin-top: -40px;
  }
}



/* 439px — Breakpoint */
@media only screen and (max-width: 439px) {
  h2 {
    font-size: 80px;
    line-height: 90%;
    margin-top: 2rem;
  }

  .design-portfolio__section h2 {
    font-size: 80px;
    line-height: 90%;
    margin-top: 2rem;
  }

  .design-portfolio__section {
    margin-bottom: -2rem;
  }


}


/* 350px — Breakpoint */
@media only screen and (max-width: 350px) {

  .design-project__card-slide {
    top: 93px;
    border-radius: 23px;
  }


}

