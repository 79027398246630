
footer {
    padding: 30px 20px;
    -webkit-box-shadow: inset 0px 15px 20px 0px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: inset 0px 15px 20px 0px rgba(0, 0, 0, 0.20);
    box-shadow: inset 0px 15px 20px 0px rgba(0, 0, 0, 0.20);
    background: var(--darker-gray);
    position:relative;
    left:0;
    bottom:0;
    right:0;
    margin-top: 5rem;

}

.footer__content-container {
    display: flex;
    justify-content: space-between;
}

.footer__logo {
    display: block;
    height: 32px;
    margin: 0 auto;
}

.footer__social-icons-container {
    display: flex;
    justify-content: space-between;
    
}

.footer__emoji {
    margin-right: 2px;
}

.footer__react {
    font-size: 23px;
    margin-top: -5px;
    color: #61dafb;
}

.footer__figma {
    font-size: 21px;
    color: #E65631;
}

.footer__built-with {
    margin-top: 30px;
    border-top: solid 1px var(--mid-grey);
    padding: 30px 0 0 0;
    color: white;
    text-decoration: underline;
    opacity: .4;
    text-align: center;
    display: block;
}

.footer__resume {
    color: rgb(233, 233, 233);
    text-align: center;
    background-color: var(--section-headlines);
    max-width: 200px;
    padding: .375rem 1rem;
    border-radius: 50px;
    margin-left: 60px;
}


.footer__social-icons {
    margin-left: 16px;
    color: white;
    transition: ease .5s;
    font-size: 20px;
} 

.footer__social-icons:focus, 
.footer__social-icons:hover,
.footer__built-with:hover,
.footer__social-icons:focus,
.footer__resume:hover,
.footer__resume:focus{
    color: white;
    opacity: .5;
    text-decoration: none;
    transition: ease .5s;

  }
  
  .footer__social-icons:active,
  .footer__social-icons:visited,
  .footer__built-with:visited,
  .footer__built-with:active,
  .footer__resume:active,
  .footer__resume:visited {
    color: white;
    transition: ease .5s;
  }

  @media only screen and (max-width: 439px) { 
    .footer__content-container {
        flex-direction: column;
    }

    .footer__social-icons-container {
        display: flex;
        justify-content: center;
    }

    .footer__resume {
        margin: 30px auto;
    }

    .footer__built-with {
        padding: 30px 0 0;
    }
  }